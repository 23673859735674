import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  OrganizationStateQueryT,
  OrganizationStateT,
  useActiveImagesAssetLinesQuery,
  useActiveTextsAssetLinesQuery,
  useNotDeletedImagesAssetLinesQuery,
  useNotDeletedTextsAssetLinesQuery,
  useOrganizationStateQuery,
} from "../graphql/generated/graphql";
import { useChangeState } from "./AdvancedSetup/useChangeState";
import { CreditModal } from "./Credits/Modal";
import { DisabledIcon, Icon, PlusIcon } from "./Icons";

const getState = (data?: OrganizationStateQueryT) => {
  if (data?.organization?.state === OrganizationStateT.PausedT) {
    return "paused";
  }
  if (data?.organization?.state === OrganizationStateT.NoCreditT) {
    return "no_credit";
  }
  if (data?.organization?.state === OrganizationStateT.NoProductsT) {
    return "no_products";
  }
  if (data?.organization?.state === OrganizationStateT.AccountSuspendedT) {
    return "account_suspended";
  }
  if (data?.organization?.summaryStats.impressions === 0) {
    return "activating";
  }

  return "active";
};

export const CampaignState = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.CampaignState" });
  const navigate = useNavigate();
  const [creditModalOpened, setCreditModalOpened] = useState(false);
  const [creditModalCounter, setCreditModalCounter] = useState(0);
  const { data, loading } = useOrganizationStateQuery();
  const { changeCampaignSetting, updateInProgress } = useChangeState();

  const { data: dataActiveImageAssetLines } = useActiveImagesAssetLinesQuery();
  const { data: dataActiveTextAssetLines } = useActiveTextsAssetLinesQuery();
  const { data: dataNotDeletedImageAssetLines } = useNotDeletedImagesAssetLinesQuery();
  const { data: dataNotDeletedTextAssetLines } = useNotDeletedTextsAssetLinesQuery();

  const hasAnyDisapproved =
    (dataActiveImageAssetLines?.organization?.activeImagesAssetLines || []).filter((line) => line.googleDisapproved)
      .length > 0 ||
    (dataActiveTextAssetLines?.organization?.activeTextsAssetLines || []).filter((line) => line.googleDisapproved)
      .length > 0;

  const hasAnyDrafts = !!(
    (dataNotDeletedImageAssetLines?.organization?.notDeletedImagesAssetLines || []).find((line) => line.draftState) ||
    (dataNotDeletedTextAssetLines?.organization?.notDeletedTextsAssetLines || []).find((line) => line.draftState)
  );

  const state = loading ? "unknown" : getState(data);

  return (
    <div
      data-test-id="campaign-state"
      style={{
        border: "1px solid #E9E9E9",
        minWidth: "267px",
        position: "relative",
        padding: "20px",
        margin: "40px 0",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: "25px",
          color: "#37373D",
          background: "white",
          padding: "0px 10px",
        }}
      >
        {t("BoxLabel")}
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <span style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>{t("Current state label")}</span>
          {loading ? (
            <Icon kind="loader" />
          ) : (
            <>
              {state === "paused" && (
                <>
                  <DisabledIcon /> <span style={{ color: "#db1b4e", marginLeft: "5px" }}>{t("State paused")}</span>
                </>
              )}
              {state === "activating" && (
                <>
                  <Icon kind="loader" />
                  <span style={{ color: "#14B1EF", marginLeft: "5px" }}>
                    <Trans components={{ strong: <strong /> }} i18nKey="State activating" t={t} />
                  </span>
                </>
              )}
              {state === "no_credit" && (
                <>
                  <DisabledIcon />{" "}
                  <span style={{ color: "#db1b4e", marginLeft: "5px" }}>{t("State paused due to credit")}</span>
                </>
              )}
              {state === "no_products" && (
                <>
                  <DisabledIcon />{" "}
                  <span style={{ color: "#db1b4e", marginLeft: "5px" }}>{t("State paused due to products")}</span>
                </>
              )}
              {state === "account_suspended" && (
                <>
                  <DisabledIcon />{" "}
                  <span style={{ color: "#db1b4e", marginLeft: "5px" }}>{t("State paused due to suspension")}</span>
                </>
              )}
              {state === "active" && (
                <>
                  <Icon kind="disable-property" />{" "}
                  <span style={{ color: "#9DCA57", marginLeft: "5px" }}>{t("State active")}</span>
                </>
              )}
            </>
          )}
        </span>
        {state === "paused" && (
          <Button
            disabled={updateInProgress}
            variant="action"
            onClick={() => changeCampaignSetting(OrganizationStateT.ActiveT)}
          >
            {t("Reactivate button")}
          </Button>
        )}
        {state === "no_credit" && (
          <Button variant="action" onClick={() => setCreditModalOpened(true)}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <PlusIcon /> &nbsp; {t("Increase credits button")}
            </span>
          </Button>
        )}
      </div>

      {(hasAnyDrafts || hasAnyDisapproved) && <hr style={{ borderColor: "#E9E9E9", margin: "16px 0" }} />}
      {hasAnyDrafts && (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "8px" }}>
            <Icon kind="info-item" />
            <strong style={{ color: "#14B1EF" }}>{t("Unpublished asset changes")}</strong>
          </div>
          <Button variant="action" onClick={() => navigate("/assets")}>
            {t("View Assets")}
          </Button>
        </div>
      )}
      {hasAnyDisapproved && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: hasAnyDrafts ? "16px" : 0,
          }}
        >
          <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "8px" }}>
            <Icon kind="warning" />
            <strong style={{ color: "#DB1B4E" }}>{t("Disapproved assets")}</strong>
          </div>
          <Button variant="action" onClick={() => navigate("/assets")}>
            {t("Replace Assets")}
          </Button>
        </div>
      )}
      <CreditModal
        key={`credit-modal-${creditModalCounter}`}
        isOpen={creditModalOpened}
        onClose={() => {
          setCreditModalOpened(false);
          setCreditModalCounter((counter) => counter + 1);
        }}
      />
    </div>
  );
};
