import React, { useCallback, useEffect, useState } from "react";
import { useUpdateOrganizationMutation } from "../graphql/generated/graphql";
import { ModalWrapper } from "./ModalWrapper";

type CampaignStopQuestionnairePropsT = {
  shoptetId: string;
  stopCampaignQuestionnairePending: boolean;
  userEmail: string;
};

export const CampaignStopQuestionnaire = ({
  shoptetId,
  stopCampaignQuestionnairePending,
  userEmail,
}: CampaignStopQuestionnairePropsT) => {
  const [updateOrganizationMutation] = useUpdateOrganizationMutation();
  const [modalOpen, setModalOpen] = useState(stopCampaignQuestionnairePending);
  const canModalBeClosed = !stopCampaignQuestionnairePending;

  useEffect(() => {
    if (stopCampaignQuestionnairePending) {
      setModalOpen(true);
    }
  }, [stopCampaignQuestionnairePending]);

  const handleFormCompletion = useCallback(
    (event: MessageEvent) => {
      const { data, origin } = event;
      if (origin === "https://tally.so" && data.includes("FormSubmitted")) {
        updateOrganizationMutation({ variables: { properties: { stopCampaignQuestionnairePending: false } } });
      }
    },
    [updateOrganizationMutation]
  );

  useEffect(() => {
    window.addEventListener("message", handleFormCompletion);
    return () => {
      window.removeEventListener("message", handleFormCompletion);
    };
  }, [handleFormCompletion]);

  const handleClose = () => {
    if (canModalBeClosed) {
      setModalOpen(false);
    } else {
      return;
    }
  };

  return (
    <ModalWrapper isOpen={modalOpen} onClose={handleClose}>
      <iframe
        src={`https://tally.so/r/w2brWj?email=${userEmail}&id_shop=${shoptetId}`}
        style={{ width: "100%", height: "400px", border: "none" }}
        title="Campaign Stop Questionnaire"
      ></iframe>
    </ModalWrapper>
  );
};

export default CampaignStopQuestionnaire;
