import React from "react";
import { useTranslation } from "react-i18next";
import { InfoBox, WarningBox } from "../InfoBox";
import { ModalWrapper } from "../ModalWrapper";
import { Products } from "../Products";
import { formatIssueDescription } from "./index";

type ModalPropsT = {
  isOpen: boolean;
  issueId: string;
  issueName: string;
  issueType: string;
  onClose: () => void;
};

export const ProductsModal = ({ isOpen, issueId, issueName, issueType, onClose }: ModalPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Dashboard Page" });
  const { t: tGmc } = useTranslation("translation", { keyPrefix: "Components.GMCIssues" });

  const warningType = "gmc_warning";
  const errorType = "gmc_error";

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t("Products with recommendation")}
      disableScrollRestore
      positionBottom
      onClose={onClose}
    >
      {issueType === warningType && (
        <InfoBox style={{ margin: "15px 0px" }}>{formatIssueDescription(tGmc, issueName)}</InfoBox>
      )}
      {issueType === errorType && <WarningBox>{formatIssueDescription(tGmc, issueName)}</WarningBox>}
      <Products gmcError={issueName} gmcIssueId={issueId} gmcWarning={issueName} />
    </ModalWrapper>
  );
};
