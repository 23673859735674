import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import {
  OrganizationStateT,
  useOrganizationSettingsQuery,
  useUpdateOrganizationMutation,
} from "../../graphql/generated/graphql";
import { Accordion } from "../Accordion";
import { InfoBox, WarningBox } from "../InfoBox";
import { ModalWrapper } from "../ModalWrapper";
import { SmallLoader } from "../SmallLoader";
import { useChangeState } from "./useChangeState";

export const Deactivate = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Deactivate" });
  const [modalOpened, setModalOpened] = useState(false);
  const { data, loading } = useOrganizationSettingsQuery();
  const { changeCampaignSetting, updateInProgress } = useChangeState();
  const [updateOrganizationMutation] = useUpdateOrganizationMutation();

  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  const active = data.organization.state !== OrganizationStateT.PausedT;
  return (
    <Accordion header={active ? t("Headline active") : t("Headline paused")}>
      {active ? (
        <>
          <WarningBox>{t("Deactivate WarningBox")}</WarningBox>
          <Button
            data-test-id="deactivate"
            disabled={updateInProgress}
            variant="destructive"
            onClick={() => setModalOpened(true)}
          >
            {t("Deactivate button")}
          </Button>
        </>
      ) : (
        <>
          <InfoBox>{t("Reactivate InfoBox")}</InfoBox>
          <Button
            data-test-id="reactivate"
            disabled={updateInProgress}
            variant="action"
            onClick={() => changeCampaignSetting(OrganizationStateT.ActiveT)}
          >
            {t("Reactivate button")}
          </Button>
        </>
      )}
      <ModalWrapper isOpen={modalOpened} title={t("Deactivate Modal Headline")} onClose={() => setModalOpened(false)}>
        <div style={{ maxWidth: "400px" }}>
          <p>{t("Deactivate Modal Text")}</p>
        </div>
        <div className="btn-fullwidth-container" style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
          <Button data-test-id="deactivate-cancel" variant="secondary" onClick={() => setModalOpened(false)}>
            {t("Deactivate Modal Cancel")}
          </Button>
          <div style={{ width: "100%", marginLeft: "10px" }}>
            <Button
              data-test-id="deactivate-proceed"
              disabled={updateInProgress}
              variant="destructive"
              onClick={() =>
                changeCampaignSetting(OrganizationStateT.PausedT).then(() => {
                  setModalOpened(false);
                  updateOrganizationMutation({ variables: { properties: { stopCampaignQuestionnairePending: true } } });
                })
              }
            >
              {t("Deactivate Modal Proceed")}
            </Button>
          </div>
        </div>
      </ModalWrapper>
    </Accordion>
  );
};
